var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isModal
      ? _c("div", { staticClass: "header" }, [
          _c(
            "h4",
            [
              !_vm.myFiles
                ? [
                    _c("span", { staticClass: "text-info" }, [
                      _vm._v(_vm._s(_vm.entity.full_name)),
                    ]),
                    _vm._v(" Files\n            "),
                  ]
                : _c("span", [_vm._v("My Files")]),
              _vm._v(" "),
              _c("download-files-button", {
                attrs: {
                  downloader: _vm.downloadRequestObj,
                  files: _vm.filesArr,
                },
                on: { startDownload: _vm.downloadAllFiles },
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: {
          "body p-2 text-left": true,
          "files-container": true,
          "pt-4": _vm.isModal,
          "pt-0": !_vm.isModal,
        },
      },
      [
        _vm.downloadRequestObj &&
        _vm.downloadRequestObj.message &&
        _vm.downloadRequestObj.status
          ? _c(
              "div",
              {
                class: {
                  alert: true,
                  "alert-danger":
                    _vm.downloadRequestObj.messageType === "error",
                  "alert-success":
                    _vm.downloadRequestObj.messageType === "success",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "close-icon",
                    attrs: { href: "/", title: "close message" },
                    on: { click: _vm.closeDownloadMessage },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                ),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.downloadRequestObj.message) +
                    "\n            "
                ),
                _vm.downloadRequestObj.status === "started"
                  ? _c("span", { staticClass: "bold" }, [
                      _vm._v("Do not close this window."),
                    ])
                  : _vm.downloadRequestObj.status === "completed" &&
                    _vm.downloadRequestObj.file_url
                  ? _c("span", [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-link p-0 btn-sm",
                          attrs: { href: "/" },
                          on: { click: _vm.downloadFile },
                        },
                        [_vm._v("Click here")]
                      ),
                      _vm._v(
                        "\n                to download it.\n                "
                      ),
                      _c("i", { staticClass: "fa fa-external-link" }),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isLoading
          ? _c("loading-icon")
          : _c(
              "div",
              [
                !_vm.isModal
                  ? [
                      _c("download-files-button", {
                        attrs: {
                          downloader: _vm.downloadRequestObj,
                          files: _vm.filesArr,
                        },
                        on: { startDownload: _vm.downloadAllFiles },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" }, [_vm._v(" ")]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.filesArr && _vm.filesArr.length
                  ? [
                      _c(
                        "ul",
                        _vm._l(_vm.filesArr, function (file) {
                          return _c(
                            "li",
                            { key: file.id, class: { small: !_vm.isModal } },
                            [
                              _c("div", { staticClass: "row ml-0" }, [
                                _c(
                                  "div",
                                  { staticClass: "col col-10 p-0 pb-2" },
                                  [
                                    _vm.isModal
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "float-left mt-n2 mr-1",
                                          },
                                          [
                                            _c("b-checkbox", {
                                              class: {
                                                "d-inline": true,
                                              },
                                              attrs: {
                                                id: "chk_file_" + file.id,
                                                value: 1,
                                                disabled: _vm.state.submitting,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.toggleSelectedFile(
                                                    file.id
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.state.form.selectedIds[
                                                    "" + file.id
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.state.form.selectedIds,
                                                    "" + file.id,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                            state.form.selectedIds[\n                                                `${file.id}`\n                                            ]\n                                        ",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: file.url,
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(file.name) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.isModal
                                      ? _c("i", {
                                          staticClass: "fa fa-external-link",
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.isModal
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col col-2 p-0 text-center mt-n1",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-link btn-sm text-danger p-1 ml-3",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.confirmDeleteFile(
                                                  file
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-close",
                                              staticStyle: {
                                                "font-size": "22px",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.state.deleteFileIds.length
                        ? _c("div", { staticClass: "col col-12 text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                attrs: { type: "button" },
                                on: { click: _vm.confirmDeleteFiles },
                              },
                              [
                                _vm._v(
                                  "\n                        Delete selected file" +
                                    _vm._s(
                                      _vm.state.deleteFileIds.length > 1
                                        ? "s"
                                        : ""
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPagination
                        ? _c("pagination", {
                            attrs: {
                              "margin-class-name": "ml-0 mb-3",
                              attributes: _vm.pagination,
                            },
                            on: { goToPageAction: _vm.goToPage },
                          })
                        : _vm._e(),
                    ]
                  : _c(
                      "div",
                      {
                        class: {
                          "file-list pb-4 text-center": true,
                          "pt-4": _vm.isModal,
                          "pt-0": !_vm.isModal,
                        },
                      },
                      [
                        _vm._v(
                          "\n                There are no files to be listed.\n                "
                        ),
                        _c("strong", [_vm._v("Add files below")]),
                        _vm._v(".\n            "),
                      ]
                    ),
                _vm._v(" "),
                _vm.isModal &&
                _vm.message &&
                _vm.message.type &&
                _vm.message.message
                  ? _c(
                      "div",
                      {
                        class: {
                          alert: true,
                          "alert-danger": _vm.message.type === "error",
                          "alert-success": _vm.message.type === "success",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.message.message) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.maxFilesAllowed ||
                !_vm.filesArr ||
                _vm.filesArr.length < _vm.maxFilesAllowed
                  ? _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "label font-weight-bolder text-uppercase",
                        },
                        [_vm._v("Add file(s):")]
                      ),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          attrs: { method: "post" },
                          on: { submit: _vm.handleUpload },
                        },
                        [
                          _c("div", { staticClass: "row m-0" }, [
                            _c(
                              "div",
                              { staticClass: "col col-8 p-0 pt-2 wrap-text" },
                              [
                                _c("input", {
                                  class: { "input-sm": !_vm.isModal },
                                  attrs: {
                                    id: "file-input",
                                    disabled: _vm.isUploading,
                                    type: "file",
                                    name: "file",
                                    multiple: "true",
                                    accept:
                                      _vm.allowedExtensions ||
                                      "application/pdf",
                                  },
                                  on: { change: _vm.addFiles },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-4 p-0 text-right" },
                              [
                                _vm.isUploading
                                  ? _c("loading-icon", {
                                      attrs: { "class-name": "text-left" },
                                    })
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        class: {
                                          "btn btn-success": true,
                                          "btn-sm": !_vm.isModal,
                                        },
                                        attrs: {
                                          disabled:
                                            !_vm.files || !_vm.files.length,
                                          type: "submit",
                                        },
                                      },
                                      [_c("i", { staticClass: "fa fa-upload" })]
                                    ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.allowedExtensions
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "alert alert-info p-2 text-sm mt-2",
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v("Allowed file types:"),
                                    ]),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.allowedExtensions) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isModal
      ? _c("div", { staticClass: "footer text-right mt-3 pt-3" }, [
          _c(
            "button",
            { staticClass: "btn btn-link", on: { click: _vm.handleClose } },
            [_vm._v("Close")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
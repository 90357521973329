<template>
    <div>
        <div v-if="isModal" class="header">
            <h4>
                <template v-if="!myFiles">
                    <!-- <span class="text-capitalize">{{ entityType }}</span> Files
                    - -->
                    <span class="text-info">{{ entity.full_name }}</span> Files
                </template>
                <span v-else>My Files</span>
                <download-files-button
                    :downloader="downloadRequestObj"
                    :files="filesArr"
                    @startDownload="downloadAllFiles"
                />
            </h4>
        </div>
        <div
            :class="{
                'body p-2 text-left': true,
                'files-container': true,
                'pt-4': isModal,
                'pt-0': !isModal,
            }"
        >
            <div
                v-if="
                    downloadRequestObj &&
                    downloadRequestObj.message &&
                    downloadRequestObj.status
                "
                :class="{
                    alert: true,
                    'alert-danger': downloadRequestObj.messageType === 'error',
                    'alert-success':
                        downloadRequestObj.messageType === 'success',
                }"
            >
                <a
                    href="/"
                    class="close-icon"
                    title="close message"
                    @click="closeDownloadMessage"
                    ><i class="fa fa-close"
                /></a>
                {{ downloadRequestObj.message }}
                <span
                    v-if="downloadRequestObj.status === 'started'"
                    class="bold"
                    >Do not close this window.</span
                >
                <span
                    v-else-if="
                        downloadRequestObj.status === 'completed' &&
                        downloadRequestObj.file_url
                    "
                >
                    <a
                        href="/"
                        class="btn btn-link p-0 btn-sm"
                        @click="downloadFile"
                        >Click here</a
                    >
                    to download it.
                    <i class="fa fa-external-link" />
                </span>
            </div>
            <loading-icon v-if="isLoading"></loading-icon>
            <div v-else>
                <template v-if="!isModal">
                    <download-files-button
                        :downloader="downloadRequestObj"
                        :files="filesArr"
                        @startDownload="downloadAllFiles"
                    />
                    <div class="clearfix">&nbsp;</div>
                </template>
                <template v-if="filesArr && filesArr.length">
                    <ul>
                        <li
                            v-for="file in filesArr"
                            :key="file.id"
                            :class="{ small: !isModal }"
                        >
                            <div class="row ml-0">
                                <div class="col col-10 p-0 pb-2">
                                    <div
                                        v-if="isModal"
                                        class="float-left mt-n2 mr-1"
                                    >
                                        <b-checkbox
                                            :id="'chk_file_' + file.id"
                                            v-model="
                                                state.form.selectedIds[
                                                    `${file.id}`
                                                ]
                                            "
                                            :value="1"
                                            :class="{
                                                'd-inline': true,
                                            }"
                                            :disabled="state.submitting"
                                            @change="
                                                toggleSelectedFile(file.id)
                                            "
                                        />
                                        <!-- <label
                                        :for="'chk_time-slot_' + file.id"
                                        class="normal-weight pt-2"
                                        >{{ time }}</label
                                    > -->
                                    </div>
                                    <a
                                        :href="file.url"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {{ file.name }}
                                    </a>
                                    <i
                                        v-if="!isModal"
                                        class="fa fa-external-link"
                                    ></i>
                                </div>
                                <div
                                    v-if="!isModal"
                                    class="col col-2 p-0 text-center mt-n1"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-link btn-sm text-danger p-1 ml-3"
                                        @click="confirmDeleteFile(file)"
                                    >
                                        <i
                                            class="fa fa-close"
                                            style="font-size: 22px"
                                        />
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div
                        v-if="state.deleteFileIds.length"
                        class="col col-12 text-center"
                    >
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="confirmDeleteFiles"
                        >
                            Delete selected file{{
                                state.deleteFileIds.length > 1 ? 's' : ''
                            }}
                        </button>
                    </div>
                    <pagination
                        v-if="hasPagination"
                        margin-class-name="ml-0 mb-3"
                        :attributes="pagination"
                        @goToPageAction="goToPage"
                    />
                </template>
                <div
                    v-else
                    :class="{
                        'file-list pb-4 text-center': true,
                        'pt-4': isModal,
                        'pt-0': !isModal,
                    }"
                >
                    There are no files to be listed.
                    <strong>Add files below</strong>.
                </div>
                <div
                    v-if="isModal && message && message.type && message.message"
                    :class="{
                        alert: true,
                        'alert-danger': message.type === 'error',
                        'alert-success': message.type === 'success',
                    }"
                >
                    {{ message.message }}
                </div>
                <div
                    v-if="
                        !maxFilesAllowed ||
                        !filesArr ||
                        filesArr.length < maxFilesAllowed
                    "
                >
                    <span class="label font-weight-bolder text-uppercase"
                        >Add file(s):</span
                    >
                    <form method="post" @submit="handleUpload">
                        <div class="row m-0">
                            <div class="col col-8 p-0 pt-2 wrap-text">
                                <input
                                    id="file-input"
                                    :disabled="isUploading"
                                    type="file"
                                    :class="{ 'input-sm': !isModal }"
                                    name="file"
                                    multiple="true"
                                    :accept="
                                        allowedExtensions || 'application/pdf'
                                    "
                                    @change="addFiles"
                                />
                            </div>
                            <div class="col col-4 p-0 text-right">
                                <loading-icon
                                    v-if="isUploading"
                                    class-name="text-left"
                                />
                                <button
                                    v-else
                                    :disabled="!files || !files.length"
                                    type="submit"
                                    :class="{
                                        'btn btn-success': true,
                                        'btn-sm': !isModal,
                                    }"
                                    class="btn btn-success"
                                >
                                    <i class="fa fa-upload"></i>
                                </button>
                            </div>
                            <div
                                v-if="allowedExtensions"
                                class="alert alert-info p-2 text-sm mt-2"
                            >
                                <strong>Allowed file types:</strong>
                                {{ allowedExtensions }}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="isModal" class="footer text-right mt-3 pt-3">
            <button class="btn btn-link" @click="handleClose">Close</button>
        </div>
    </div>
</template>

<script>
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min';
import Echo from 'laravel-echo';

import { MSG_GENERIC_ERROR } from '../../config/constants';
import xhrError from '../../utils/xhr';
import { getAllowedUploadExtensions } from '../../utils/upload';
import Pagination from '../Pagination.vue';
import DownloadFilesButton from './DownloadFilesButton.vue';

let downloadRequestClient;

export default {
    components: {
        DownloadFilesButton,
    },
    mixins: [VuejsDialogMixin, Pagination],
    data() {
        const { entity, uploadRoute, listRoute, entityType, downloadAllRoute } =
            this.options;
        let { isModal, myFiles } = this.options;

        if (isModal === undefined) isModal = true;
        if (myFiles === undefined) myFiles = false;

        // config
        const allowedExtensions = getAllowedUploadExtensions();

        return {
            state: {
                submitting: false,
                deleteFileIds: [],
                form: {
                    selectedIds: {},
                },
            },
            entity,
            entityType,
            isModal,
            uploadRoute,
            listRoute,
            message: null,
            files: [],
            filesArr: [],
            isUploading: false,
            showOTPDialog: false,
            isLoading: true,
            maxFilesAllowed: null,
            myFiles,
            linkRoute: listRoute ? `${listRoute}?paginate=1` : null,
            pagination: null,
            hasPagination: false,
            downloadAllRoute,
            downloadRequestObj: null,
            allowedExtensions,
        };
    },
    created() {
        this.$eventHub.$on('deleteFile', this.deleteFile);
    },
    beforeDestroy() {
        this.$eventHub.$off('deleteFile');
    },
    mounted() {
        this.listFiles(`${this.options.listRoute}?paginate=1`);
    },
    methods: {
        handleClose() {
            this.cancel();
        },
        addFiles(e) {
            this.files = e.target.files;
        },
        toggleSelectedFile(fileId) {
            const { deleteFileIds, form } = this.state;

            const selectedIdIdx = deleteFileIds.findIndex(
                (id) => id === fileId,
            );

            if (selectedIdIdx === -1) {
                deleteFileIds.push(fileId);
                form.selectedIds[`${fileId}`] = 1;
            } else {
                deleteFileIds.splice(selectedIdIdx, 1);
                form.selectedIds[`${fileId}`] = 0;
            }

            this.state.deleteFileIds = [...deleteFileIds];
            this.state.form.selectedIds = { ...form.selectedIds };
        },
        async listFiles(route = null) {
            let listRoute = route || this.listRoute;

            if (listRoute) {
                this.isLoading = true;

                await window.axios
                    .get(listRoute)
                    .then((result) => {
                        if (result.data) {
                            const files = result.data.data || result.data;

                            this.filesArr = files;
                        }

                        if (result.meta && result.links) {
                            this.pagination = {
                                meta: result.meta,
                                links: result.links,
                            };
                            this.hasPagination = true;
                        }
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        clearFiles() {
            const elm = document.getElementById('file-input');

            if (elm) elm.value = '';
        },
        async handleUpload(e) {
            e.preventDefault();

            if (this.files && this.files.length) {
                const formData = new FormData();
                const totalFiles = this.files.length;

                for (let i = 0; i < totalFiles; i += 1) {
                    const file = this.files[i];

                    formData.append(`file[${i}]`, file);
                }

                this.isUploading = true;
                this.message = null;

                await window.axios
                    .post(this.uploadRoute, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then((result) => {
                        if (result && result.data && result.data.message) {
                            if (this.isModal) {
                                this.message = {
                                    type: 'success',
                                    message: result.data.message,
                                };
                            } else {
                                window.toastr.success(result.data.message);
                            }

                            this.listFiles(this.linkRoute);
                        }
                    })
                    .catch((err) => {
                        this.isUploading = false;

                        let errMsg;

                        const errBag = xhrError(err);

                        errMsg = errBag ? errBag.message : MSG_GENERIC_ERROR;

                        if (!errMsg) {
                            errMsg = MSG_GENERIC_ERROR;
                        }

                        if (errMsg) {
                            if (this.isModal) {
                                this.message = {
                                    type: 'error',
                                    message: errMsg,
                                };
                            } else {
                                window.toastr.error(errMsg);
                            }
                        }
                    })
                    .finally(() => {
                        this.isUploading = false;

                        this.clearFiles();
                    });
            }
        },
        goToPage(pageNum) {
            this.listFiles(`${this.listRoute}?paginate=1&page=${pageNum}`);
        },
        async downloadAllFiles() {
            if (!this.downloadAllRoute) return;

            const loader = this.$loading.show();

            await window.axios
                .get(this.downloadAllRoute)
                .then((result) => {
                    if (result && result.data && result.data.status) {
                        const downloadRequestObj = {};

                        if (result.data.channel) {
                            require('pusher-js');

                            downloadRequestClient = new Echo(
                                window.___FMH_BROADCAST_CONFIG,
                            );

                            Object.assign(downloadRequestObj, {
                                channel: result.data.channel,
                            });

                            if (
                                downloadRequestObj &&
                                downloadRequestObj.channel
                            ) {
                                this.downloadRequestObj = downloadRequestObj;

                                downloadRequestClient
                                    .channel(downloadRequestObj.channel)
                                    .listen('.task.started', (e) => {
                                        if (
                                            !e ||
                                            !e.data ||
                                            !e.data.entity_id ||
                                            e.data.entity_id !==
                                                this.entity.uuid
                                        ) {
                                            return;
                                        }

                                        this.updateDownloadStatus(
                                            'started',
                                            e.data,
                                        );

                                        loader.hide();
                                    });

                                downloadRequestClient
                                    .channel(downloadRequestObj.channel)
                                    .listen('.task.completed', (e) => {
                                        if (
                                            !e ||
                                            !e.data ||
                                            !e.data.entity_id ||
                                            e.data.entity_id !==
                                                this.entity.uuid
                                        ) {
                                            return;
                                        }

                                        this.updateDownloadStatus(
                                            'completed',
                                            e.data,
                                        );
                                    });

                                downloadRequestClient
                                    .channel(downloadRequestObj.channel)
                                    .listen('.task.failed', (e) => {
                                        if (
                                            !e ||
                                            !e.data ||
                                            !e.data.entity_id ||
                                            e.data.entity_id !==
                                                this.entity.uuid
                                        ) {
                                            return;
                                        }

                                        this.updateDownloadStatus(
                                            'failed',
                                            e.data,
                                        );
                                    });
                            }
                        }
                    }
                })
                .catch((err) => {
                    let errMsg;

                    const errBag = xhrError(err);

                    errMsg = errBag ? errBag.message : MSG_GENERIC_ERROR;

                    if (!errMsg) {
                        errMsg = MSG_GENERIC_ERROR;
                    }

                    if (errMsg) {
                        if (this.isModal) {
                            this.message = {
                                type: 'error',
                                message: errMsg,
                            };
                        } else {
                            window.toastr.error(errMsg);
                        }
                    }

                    loader.hide();
                });
        },
        updateDownloadStatus(event, data) {
            const { downloadRequestObj } = this;

            if (downloadRequestObj && downloadRequestObj.channel) {
                if (event === 'failed') {
                    Object.assign(downloadRequestObj, {
                        status: event,
                        message: MSG_GENERIC_ERROR,
                        messageType: 'error',
                    });
                } else {
                    Object.assign(downloadRequestObj, {
                        status: event,
                        message: data.message || '',
                        file_url: data.file_url || '',
                        messageType: 'success',
                    });
                }

                this.downloadRequestObj = {
                    ...downloadRequestObj,
                };

                // disconnect socket once we're all done.
                if (
                    downloadRequestClient &&
                    ['failed', 'completed'].includes(event)
                ) {
                    downloadRequestClient.disconnect();

                    downloadRequestClient = null;
                }
            }
        },
        closeDownloadMessage(e) {
            e.preventDefault();

            const { downloadRequestObj } = this;

            this.downloadRequestObj = {
                ...downloadRequestObj,
                message: null,
            };
        },
        downloadFile(e) {
            e.preventDefault();

            const { downloadRequestObj } = this;

            if (!downloadRequestObj || !downloadRequestObj.file_url) {
                return;
            }

            window.open(downloadRequestObj.file_url);

            this.downloadRequestObj = null;
        },
        confirmDeleteFile(data) {
            if (!data || !data.id) return;

            this.$dialog
                .confirm(
                    `<div class="my-3 text-info">We will send an authorization code to your account administrator, which you will have to confirm.</div>
                    Are you sure you want to delete <span class="text-warning italic">${data.name}</span>?`,
                    {
                        okText: 'Yes',
                        cancelText: 'Cancel',
                        html: true,
                    },
                )
                .then(() => {
                    const loader = this.$loading.show();

                    let otpUrl = data.url;

                    if (otpUrl && otpUrl.indexOf('?') !== -1) {
                        otpUrl = otpUrl.split('?')[0];
                    }

                    otpUrl = otpUrl.replace(`${data.path}`, `otp/${data.id}`);

                    otpUrl = otpUrl.replace('files/', 'api/files/');

                    window.axios
                        .post(otpUrl)
                        .then((result) => {
                            if (result && result.data) {
                                this.$eventHub.$emit('showOTPDialog', {
                                    id: data.id,
                                    entityId: this.entity.uuid,
                                    expires: result.data.expires,
                                    requestUrl: otpUrl,
                                });
                            }
                        })
                        .catch((err) => {
                            const errBag = xhrError(err);
                            const errMsg = errBag
                                ? errBag.message
                                : MSG_GENERIC_ERROR;
                            window.toastr.error(errMsg);
                        })
                        .finally(() => {
                            loader.hide();
                        });
                })
                .catch(() => {});
        },
        deleteFile(fileId, otpId) {
            const loader = this.$loading.show();

            window.axios
                .delete(`/api/files/${fileId}/otp/${otpId}`)
                .then(() => {
                    let { filesArr } = this;
                    let { deleteFileIds } = this.state;

                    // remove file(s) from view
                    if (
                        filesArr &&
                        Array.isArray(filesArr) &&
                        filesArr.length
                    ) {
                        if (!deleteFileIds.length) {
                            filesArr = filesArr.filter(
                                (itm) => itm && itm.id !== fileId,
                            );
                        } else {
                            filesArr = filesArr.filter(
                                (itm) =>
                                    itm && deleteFileIds.indexOf(itm.id) === -1,
                            );
                        }

                        this.filesArr = filesArr;
                    }

                    window.toastr.success('File deleted successfully.');
                })
                .catch((err) => {
                    const errBag = xhrError(err);
                    const errMsg = errBag ? errBag.message : MSG_GENERIC_ERROR;
                    window.toastr.error(errMsg);
                })
                .finally(() => {
                    loader.hide();
                });
        },
        confirmDeleteFiles() {
            const { filesArr } = this;
            const { deleteFileIds } = this.state;

            if (!deleteFileIds || !deleteFileIds.length) {
                this.$dialog
                    .alert('You must select at least one file to delete.', {
                        okText: 'Ok',
                    })
                    .catch(() => {});

                return false;
            }

            let fileData = null;

            // select first file
            if (filesArr && Array.isArray(filesArr) && filesArr.length) {
                fileData = filesArr.find((itm) => itm.id === deleteFileIds[0]);
            }

            if (!fileData) return;

            const fileIds = deleteFileIds.join(',');

            this.$dialog
                .confirm(
                    `<div class="my-3 text-info">We will send an authorization code to your account administrator, which you will have to confirm.</div>
                    Are you sure you want to delete selected files?`,
                    {
                        okText: 'Yes',
                        cancelText: 'Cancel',
                        html: true,
                    },
                )
                .then(() => {
                    const loader = this.$loading.show();
                    let otpUrl = fileData.url;

                    if (otpUrl && otpUrl.indexOf('?') !== -1) {
                        otpUrl = otpUrl.split('?')[0];
                    }

                    otpUrl = otpUrl.replace(
                        `${fileData.path}`,
                        `otp/${fileIds}`,
                    );

                    otpUrl = otpUrl.replace('files/', 'api/files/');

                    window.axios
                        .post(otpUrl)
                        .then((result) => {
                            if (result && result.data) {
                                this.$eventHub.$emit('showOTPDialog', {
                                    id: fileData.id,
                                    entityId: this.entity.uuid,
                                    expires: result.data.expires,
                                    requestUrl: otpUrl,
                                });
                            }
                        })
                        .catch((err) => {
                            const errBag = xhrError(err);
                            const errMsg = errBag
                                ? errBag.message
                                : MSG_GENERIC_ERROR;
                            window.toastr.error(errMsg);
                        })
                        .finally(() => {
                            loader.hide();
                        });
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped>
.header {
    border-bottom: 1px solid #eee;
}

.header h4 {
    padding-bottom: 0;
    margin-bottom: 5px;
    margin-top: 0px;
}

.body .label {
    color: inherit;
    font-size: 13px;
    padding-left: 0;
}

/* .body {
    min-height: 700px;
} */
.body ul {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    list-style: none;
    padding-left: 0;
    /* overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px; */
}
.btn-success {
    width: unset !important;
}

.footer {
    border-top: 1px solid #eee;
}

.custom-checkbox >>> label {
    padding-top: 0.5rem !important;
}

div >>> .wrap-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.files-container >>> .alert .close-icon {
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 5px;
    color: #666;
}
</style>

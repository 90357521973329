<template>
    <div :class="classNames">
        <button
            :class="{
                'btn btn-link p-0': true,
                'has-glow': totalFiles > 0,
            }"
            @click="showFiles"
        >
            <i v-if="!text" class="fa fa-files-o"></i>
            <span v-else>{{ text }}</span>
        </button>
    </div>
</template>

<script>
const { Vue } = window;

import { parseData } from '../../utils/xhr';
import ListView from './List';
import ConfirmOTP from '../ConfirmOTP.vue';

Vue.dialog.registerComponent('entity-files', ListView);
Vue.dialog.registerComponent('confirm-otp', ConfirmOTP);

export default {
    name: 'EntityFilesButton',
    props: {
        entity: {
            type: String,
            required: true,
        },
        'upload-route': {
            type: String,
            required: true,
        },
        'list-route': {
            type: String,
            required: true,
        },
        'entity-type': {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
        'class-names': {
            type: String,
            default: '',
        },
        'my-files': {
            type: Boolean,
            default: false,
        },
        'download-all-route': {
            type: String,
            default: null,
        },
        'total-files': {
            type: Number,
            default: 0,
        },
    },
    data() {
        let data = null;

        try {
            data = this.entity ? parseData(this.entity) : null;
        } catch (err) {
            //
        }

        // if (this.isShowingOTPDialog) {

        // }

        return {
            isShowingOTPDialog: false,
            record: data,
            dialogObj: null,
        };
    },
    created() {
        this.$eventHub.$on('showOTPDialog', this.showOTPDialog);
    },
    beforeDestroy() {
        this.$eventHub.$off('showOTPDialog');
    },
    methods: {
        showFiles() {
            this.$dialog
                .alert('Files', {
                    view: 'entity-files',
                    entity: this.record,
                    entityType: this.entityType,
                    uploadRoute: this.uploadRoute,
                    listRoute: this.listRoute,
                    downloadAllRoute: this.downloadAllRoute,
                    myFiles: this.myFiles,
                    customClass: 'entity-files-container',
                })
                .catch(() => {});
        },
        showOTPDialog(data) {
            // This prevents 'showOTPDialog' event being emitted multiple times,
            // in instances where we have multiple file dialog buttons.
            if (
                !this.record ||
                !this.record.uuid ||
                !data ||
                !data.entityId ||
                this.record.uuid !== data.entityId
            )
                return;

            this.$dialog
                .alert('Confirm Code', {
                    view: 'confirm-otp',
                    data,
                    confirmAction: (fileId) => {
                        this.$eventHub.$emit('deleteFile', data.id, fileId);
                    },
                    customClass: 'confirm-otp-dialog',
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped>
.header-dropdown-item .dropdown-item .btn-link {
    color: #222 !important;
}
.has-glow {
    text-shadow: 0 0 10px #007bff, 0 0 5px #007bff, 0 0 10px #007bff,
        0 0 30px #007bff;
}
</style>

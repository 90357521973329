<template>
    <div v-if="files.length" class="float-right download-icon">
        <loading-icon
            v-if="
                downloader &&
                downloader.status &&
                downloader.status === 'started'
            "
        />
        <a
            v-else
            href="/"
            class="btn btn-dark text-success p-1"
            title="download all files as a single pdf"
            @click="startDownload"
        >
            <i class="fa fa-cloud-download" /> <span>Download all Files</span>
        </a>
    </div>
</template>
<script>
export default {
    name: 'DownloadFilesButton',
    props: {
        downloader: {
            type: Object,
            default: null,
        },
        files: {
            type: Array,
            default: () => [],
        },
    },
    // data() {},
    methods: {
        startDownload(evt) {
            if (evt) evt.preventDefault();

            this.$emit('startDownload');
        },
    },
};
</script>
<style scoped>
.download-icon >>> a >>> i {
    font-size: 22px;
}
.download-icon >>> a:hover {
    text-decoration: none;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.files.length
    ? _c(
        "div",
        { staticClass: "float-right download-icon" },
        [
          _vm.downloader &&
          _vm.downloader.status &&
          _vm.downloader.status === "started"
            ? _c("loading-icon")
            : _c(
                "a",
                {
                  staticClass: "btn btn-dark text-success p-1",
                  attrs: {
                    href: "/",
                    title: "download all files as a single pdf",
                  },
                  on: { click: _vm.startDownload },
                },
                [
                  _c("i", { staticClass: "fa fa-cloud-download" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Download all Files")]),
                ]
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
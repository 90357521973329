export const getAllowedUploadExtensions = (configType = null) => {
    // let returnVar = null;

    let allowedExtensions = '';

    if (
        window.Laravel &&
        window.Laravel.config &&
        window.Laravel.config.upload
    ) {
        const uploadConfig = window.Laravel.config.upload;

        if ((!configType || configType === 'files') && uploadConfig) {
            allowedExtensions += uploadConfig.allowed_file_extensions;
        }

        if ((!configType || configType === 'images') && uploadConfig) {
            if (allowedExtensions) allowedExtensions += ',';

            allowedExtensions += uploadConfig.allowed_image_extensions;
        }

        if (allowedExtensions) {
            const allowedExtensionsArr = allowedExtensions.split(',');

            allowedExtensions = allowedExtensionsArr.join(', ');
        }
    }

    return allowedExtensions;
};

export const getMaxUploadSizeMB = (key = null) => {
    let maxSize = '';

    if (
        key &&
        window.Laravel &&
        window.Laravel.config &&
        window.Laravel.config.upload
    ) {
        const uploadConfig = window.Laravel.config.upload;

        maxSize = uploadConfig[key];

        if (maxSize && Number.parseInt(maxSize, 10)) {
            const baseValue = 1024; // bytes

            const baseValueExp = Math.floor(
                Math.log(maxSize) / Math.log(baseValue),
            );

            maxSize = parseFloat(
                (maxSize / Math.pow(baseValue, baseValueExp)).toFixed(2),
            );
        }
    }

    return maxSize;
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirm-otp-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.state.message
      ? _c(
          "div",
          {
            class: {
              "pt-3 text-center": true,
              "text-danger": _vm.state.error,
              "text-success": !_vm.state.error,
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.state.message) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("form", { on: { submit: _vm.confirmOtp } }, [
      _c(
        "div",
        { staticClass: "row ml-0" },
        [
          _vm.otpConfig && _vm.otpConfig.code_length
            ? _c("div", { staticClass: "col col-12 my-3 pl-0" }, [
                _vm._v(
                  "\n                Please enter a " +
                    _vm._s(_vm.otpConfig.code_length) +
                    " digits code\n                below.\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group col col-12 p-0 mb-3" },
            [
              _c("otp-input", {
                ref: "otpInput",
                attrs: {
                  "input-classes": "otp-input",
                  separator: "-",
                  "num-inputs": _vm.otpConfig.code_length,
                  "should-auto-focus": true,
                  "is-input-num": true,
                },
                on: {
                  "on-change": _vm.handleCodeChange,
                  "on-complete": _vm.handleCompleteCode,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.state.otp.dirty && _vm.state.otp.error
            ? _c("div", { staticClass: "input-error col-12 pl-0" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.getCodeErrorMessage) +
                    "\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12 my-2 pl-0 text-info" }, [
            _vm._v(
              "\n                One time code will expire in\n                "
            ),
            _c("span", { staticClass: "code-expiration-countdown" }, [
              _vm._v(_vm._s(_vm.otpConfig.expiry_minutes)),
            ]),
            _vm._v("\n                minutes.\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-12" }, [
            _c("div", { staticClass: "row ml-0" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success col-6 mr-2",
                  attrs: {
                    type: "submit",
                    disabled: _vm.state.submitting || _vm.state.sending,
                  },
                },
                [
                  _vm._v(
                    "\n                        Confirm\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger col-5",
                  attrs: {
                    type: "button",
                    disabled: _vm.state.submitting || _vm.state.sending,
                  },
                  on: { click: _vm.closeModal },
                },
                [
                  _vm._v(
                    "\n                        Cancel\n                    "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("resend-otp-code-button", {
            attrs: {
              "otp-config": _vm.otpConfig,
              "resend-data": _vm.state.data,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h4", [_vm._v("Confirm Code")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
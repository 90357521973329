var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classNames }, [
    _c(
      "button",
      {
        class: {
          "btn btn-link p-0": true,
          "has-glow": _vm.totalFiles > 0,
        },
        on: { click: _vm.showFiles },
      },
      [
        !_vm.text
          ? _c("i", { staticClass: "fa fa-files-o" })
          : _c("span", [_vm._v(_vm._s(_vm.text))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <!-- <b-modal
        id="confirm-otp"
        no-fade
        title="Confirm Code"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
    > -->
    <div class="confirm-otp-container">
        <div class="header">
            <h4>Confirm Code</h4>
        </div>
        <div
            v-if="state.message"
            :class="{
                'pt-3 text-center': true,
                'text-danger': state.error,
                'text-success': !state.error,
            }"
        >
            {{ state.message }}
        </div>
        <form @submit="confirmOtp">
            <div class="row ml-0">
                <div
                    v-if="otpConfig && otpConfig.code_length"
                    class="col col-12 my-3 pl-0"
                >
                    Please enter a {{ otpConfig.code_length }} digits code
                    below.
                </div>
                <div class="form-group col col-12 p-0 mb-3">
                    <otp-input
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="otpConfig.code_length"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleCodeChange"
                        @on-complete="handleCompleteCode"
                    />
                </div>
                <div
                    v-if="state.otp.dirty && state.otp.error"
                    class="input-error col-12 pl-0"
                >
                    {{ getCodeErrorMessage }}
                </div>
                <div class="col col-12 my-2 pl-0 text-info">
                    One time code will expire in
                    <span class="code-expiration-countdown">{{
                        otpConfig.expiry_minutes
                    }}</span>
                    minutes.
                </div>
                <div class="col col-12">
                    <div class="row ml-0">
                        <button
                            type="submit"
                            class="btn btn-success col-6 mr-2"
                            :disabled="state.submitting || state.sending"
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger col-5"
                            :disabled="state.submitting || state.sending"
                            @click="closeModal"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <resend-otp-code-button
                    :otp-config="otpConfig"
                    :resend-data="state.data"
                />
            </div>
        </form>
        <!-- </b-modal> -->
    </div>
</template>
<script>
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min';
import OtpInput from '@bachdgvn/vue-otp-input';

import { MSG_GENERIC_ERROR } from '../config/constants';
import xhrError from '../utils/xhr';

export default {
    name: 'ConfirmOTP',
    components: {
        OtpInput,
    },
    mixins: [VuejsDialogMixin],
    props: {
        data: {
            type: Object,
            default: null,
        },
        'confirm-action': {
            type: Object,
            default: null,
        },
        'cancel-action': {
            type: Object,
            default: null,
        },
    },
    data() {
        const data = this.data || this.options.data;
        const confirmAction = this.confirmAction || this.options.confirmAction;
        const cancelAction = this.cancelAction || this.options.cancelAction;
        let otpConfig = {};
        let resendCodeWaitMinutes = null;

        try {
            otpConfig = window.Laravel.config.otp;

            resendCodeWaitMinutes = otpConfig.request_wait_time_minutes
                ? otpConfig.request_wait_time_minutes * 60
                : null;
        } catch {
            otpConfig = {};
        }

        return {
            form: {
                code: '',
            },
            otpConfig,
            resendCodeWaitMinutes,
            resendCodeTimeLeftCounter: resendCodeWaitMinutes,
            state: {
                data,
                submitting: false,
                sending: false,
                error: false,
                message: null,
                timer: null,
                canResendCode: false,
                confirmAction,
                cancelAction,
                otp: {
                    dirty: false,
                    error: false,
                    length: false,
                },
            },
            confirmOtpTimeout: null,
        };
    },
    computed: {
        getCodeErrorMessage: function () {
            return this.getErrorMessage('code');
        },
    },
    beforeDestroy() {
        if (this.confirmOtpTimeout) clearTimeout(this.confirmOtpTimeout);
    },
    methods: {
        handleCodeChange(value) {
            this.form.code = value;
            this.state.otp.dirty = true;
        },
        handleCompleteCode(value) {
            this.form.code = value;

            this.confirmOtp();
        },
        getErrorMessage() {
            if (this.state.otp.error && this.state.otp.length) {
                return 'Too short!';
            }

            return 'Required!';
        },
        async confirmOtp(e) {
            const { state, form, otpConfig } = this;

            if (e) e.preventDefault();

            state.message = null;
            state.error = false;
            state.otp.error = false;
            state.otp.length = false;

            if (
                !form.code ||
                form.code.toString().length < otpConfig.code_length
            ) {
                this.state.otp.error = true;

                if (
                    form.code &&
                    form.code.toString().length < otpConfig.code_length
                ) {
                    this.state.otp.length = true;
                }

                return;
            }

            state.submitting = true;

            const data = this.form;

            const loader = this.$loading.show();

            await window.axios
                .post('/api/otp/confirm', data)
                .then((res) => {
                    if (res && res.data && state.confirmAction) {
                        state.confirmAction(res.data.id);
                    }

                    this.confirmOtpTimeout = setTimeout(() => {
                        this.closeModal();
                    }, 200);
                })
                .catch((err) => {
                    state.error = true;
                    state.message = true;

                    const errBag = xhrError(err);
                    const errMsg = errBag ? errBag.message : MSG_GENERIC_ERROR;

                    state.message = errMsg;
                })
                .finally(() => {
                    this.state.submitting = false;

                    loader.hide();
                });
        },
        closeModal() {
            if (this.state.cancelAction) this.state.cancelAction();

            this.cancel();
        },
    },
};
</script>
<style scoped>
.confirm-otp-container >>> button.btn {
    width: 100%;
}
.confirm-otp-container >>> .header {
    border-bottom: 1px solid #eee;
}

.confirm-otp-container >>> .header h4 {
    padding-bottom: 0;
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 20px;
}
</style>
